<template>
    <div class="wrap">
        <head-nav></head-nav>
        <div class="banner">
            <swiper class="max-size" :options="swiperOption">
                <swiper-slide class="max-size"
                  v-for="(item,i) of 4"
                  :key="i"
                >
                   <img src="@/assets/img/banner-1.png" width="100%" height="100%" data-fit-cover style="max-height:600px; vertical-align: middle;" alt="banner 图">
                </swiper-slide>
                <!-- <div class="swiper-button-next" slot="button-next"></div>
                <div class="swiper-button-prev" slot="button-prev"></div> -->
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="z-h-y-l-p-t-y-f-y-y-wrap" style="background-color:#F5F6F8;">
            <div class="z-h-y-l-p-t-y-f-y-y-box content-w">
                <div class="module-title">智慧养老平台研发运营</div>
                <div class="module-title-hint">智/慧/养/老/平/台/研/发/运/营</div>
                <div class="z-h-y-l-p-t-y-f-y-y-content">
                    <div class="z-h-y-l-p-t-y-f-y-y-text">以“物联网、互联网、云计算、大数据、人工智能”为核心技术，围绕养老服务场景，持续完善居家为基础、社区为依托、机构为补充、医养相结合、上层有监管的养老服务体系，构建养老行业全生命周期闭环生态开放平台。</div>
                    <auto-row class="z-h-y-l-p-t-y-f-y-y-list" :wrap="true">
                        <auto-col class="z-h-y-l-p-t-y-f-y-y-list-item"
                            v-for="(item,i) of z_h__l_p_t_y_f_y_y_list"
                            :key="i"
                            size="0 0 290px"
                        >
                            <div class="z-h-y-l-p-t-y-f-y-y-list-item-icon">
                                <img :src="item.icon" height="100%" alt="">
                            </div>
                            <p class="z-h-y-l-p-t-y-f-y-y-list-item-label">{{item.label}}</p>
                        </auto-col>
                    </auto-row>
                </div>
            </div>
        </div>
        <div class="q-c-j-z-h-y-l-p-t-wrap" style="background-color:#F9F9F9;">
            <router-link :to="{path:'/infos'}">
                <div class="q-c-j-z-h-y-l-p-t-box content-w">
                    <div class="module-title">全场景智慧养老云平台</div>
                    <div class="module-title-hint">全/场/景/智/慧/养/老/云/平/台</div>
                    <div class="q-c-j-z-h-y-l-p-t-content">
                        <img src="@/assets/img/pic-lc.png" width="100%" alt="">
                    </div>
                </div>
            </router-link>
        </div>
        <div class="z-s-f-a-wrap" style="background-color:#F5F6F8;">
            <div class="z-s-f-a-box content-w">
                <div class="module-title">专属方案</div>
                <div class="module-title-hint">一/站/式/服/务/适/配/多/种/方/案</div>
                <div class="z-s-f-a-content">
                    <div class="z-s-f-a-list-box">
                        <auto-row class="z-s-f-a-list" :wrap="true">
                            <auto-col class="z-s-f-a-list-item"
                                v-for="(item,i) of z_s_f_a_list"
                                :key="i"
                                min="425"
                                size="440"
                            >
                                <!-- <div class="z-s-f-a-list-item-box">
                                    <div class="z-s-f-a-list-item-icon">
                                        <img class="default" :src="item.icons[0]" height="100%" alt="" />
                                        <img class="highlight" :src="item.icons[1]" height="100%" alt="" />
                                    </div>
                                    <p class="z-s-f-a-list-item-label">{{item.label}}</p>
                                    <div class="z-s-f-a-list-item-text">
                                        <p v-for="(text,index) of item.texts" :key="index">{{text}}</p>
                                    </div>
                                </div> -->
                                <div class="z-s-f-a-list-item-icon">
                                    <img class="default" :src="item.icons[0]" height="100%" alt="" />
                                    <img class="highlight" :src="item.icons[1]" height="100%" alt="" />
                                </div>
                                <p class="z-s-f-a-list-item-label">{{item.label}}</p>
                                <div class="z-s-f-a-list-item-text">
                                    <p v-for="(text,index) of item.texts" :key="index">{{text}}</p>
                                </div>
                            </auto-col>
                        </auto-row>
                    </div>
                </div>
            </div>
        </div>
        <div class="q-c-j-s-p-wrap" style="background-color:#F9F9F9;">
            <div class="q-c-j-s-p-box content-w">
                <div class="module-title">全场景适配</div>
                <div class="module-title-hint">数/据/同/步  信/息/共/享  全/维/监/管  节/省/人/力</div>
                <div class="q-c-j-s-p-content">
                    <div class="q-c-j-s-p-list-box">
                        <auto-row class="q-c-j-s-p-list" :wrap="true">
                            <auto-col class="q-c-j-s-p-list-item" min="300" size="332" max="570">
                                <div class="q-c-j-s-p-list-item-label middle">WEB端</div>
                                <auto-row class="q-c-j-s-p-list-item-text" justify="center">
                                    <auto-col max="100%">
                                        <p>设施设备后台管理</p>
                                        <p>民政监管端</p>
                                        <p>照料中心端</p>
                                        <p>运营中心端</p>
                                        <p>养老院管理</p>
                                        <p>居家服务端</p>
                                        <p>……</p>
                                    </auto-col>
                                </auto-row>
                            </auto-col>
                            <auto-col class="q-c-j-s-p-list-item" min="300" size="332" max="570">
                                <div class="q-c-j-s-p-list-item-label middle">大屏端</div>
                                <auto-row class="q-c-j-s-p-list-item-text" justify="center">
                                    <auto-col max="100%">
                                        <p>数据运营可视化监</p>
                                        <p style="text-align:center;">……</p>
                                    </auto-col>
                                </auto-row>
                            </auto-col>
                            <auto-col class="q-c-j-s-p-list-item" min="300" size="332" max="570">
                                <div class="q-c-j-s-p-list-item-label middle">电视机/自助一体机</div>
                                <auto-row class="q-c-j-s-p-list-item-text" justify="center">
                                    <auto-col max="100%">
                                        <p>院内接待</p>
                                        <p>形象展示</p>
                                        <p>……</p>
                                    </auto-col>
                                </auto-row>
                            </auto-col>
                            <auto-col class="q-c-j-s-p-list-item" min="300" size="332" max="570">
                                <div class="q-c-j-s-p-list-item-label middle">手机端/小程序/PAD端</div>
                                <auto-row class="q-c-j-s-p-list-item-text" justify="center">
                                    <auto-col max="100%">
                                        <p>适用于：社会人士，包括老人及家属、民政人员、机构人员、照料中心人员、上门服务人员、商家、评估人员</p>
                                        <p>……</p>
                                    </auto-col>
                                </auto-row>
                            </auto-col>
                        </auto-row>
                    </div>

                </div>
            </div>
        </div>
        <div class="w-m-d-y-s-wrap" style="background-color:#F5F6F8;">
            <div class="w-m-d-y-s-box content-w">
                <div class="module-title">我们的优势</div>
                <div class="module-title-hint">为/政/府/监/管/助/力  为/企/业/业/务/赋/能</div>
                <div class="w-m-d-y-s-content">
                    <div class="w-m-d-y-s-list-box">
                        <auto-row class="w-m-d-y-s-list" :wrap="true">
                            <auto-col class="w-m-d-y-s-list-item" min="40%" size="680" max="50%">
                                <div class="w-m-d-y-s-list-item-label middle">懂养老更专业</div>
                                <p class="w-m-d-y-s-list-item-text">
                                    公司专注于智慧养老产业，与养老企业、组织进行全面战略合作，精准把脉养老产业走向，为养老服务奠定身后基础。
                                </p>
                            </auto-col>
                            <auto-col class="w-m-d-y-s-list-item" min="40%" size="680" max="50%">
                                <div class="w-m-d-y-s-list-item-label middle">精研发高品质</div>
                                <p class="w-m-d-y-s-list-item-text">
                                    公司核心成员均拥有超10年的互联网、物联网、大数据等技术经验。平台支持全定制服务，对接简便、快捷。产品更新迭代快，适用范围广，实用性强。
                                </p>
                            </auto-col>
                            <auto-col class="w-m-d-y-s-list-item" min="40%" size="680" max="50%">
                                <div class="w-m-d-y-s-list-item-label middle">运营更省心</div>
                                <p class="w-m-d-y-s-list-item-text">
                                    全面定制智慧养老运营方案，提供平台、运营、服务三位一体方案，真正做到智慧养老一站式服务。
                                </p>
                            </auto-col>
                            <auto-col class="w-m-d-y-s-list-item" min="40%" size="680" max="50%">
                                <div class="w-m-d-y-s-list-item-label middle">全客户端适配</div>
                                <p class="w-m-d-y-s-list-item-text">
                                    办公设备结合移动设备，随时随地了解养老数据。管理简化流程、节省人力，实现移动办公。
                                </p>
                            </auto-col>
                        </auto-row>
                    </div>

                </div>
            </div>
        </div>
        <div class="x-w-wrap" style="background-color:#F9F9F9;">
            <div class="x-w-box content-w">
                <div class="module-title">新闻</div>
                <div class="module-title-hint">新/闻/资/讯</div>
                <div class="x-w-content">
                    <div class="x-w-list-box">
                        <auto-row class="x-w-list" :wrap="true">
                            <auto-col class="x-w-list-item-frist" min="600" size="600">
                                <auto-row wrap="wrap">
                                    <auto-col class="x-w-list-item-pic" min="210" size="300">
                                        <img class="x-w-list-item-pic-img" src="@/assets/img/banner-1.png" width="100%" height="100%" alt="">
                                    </auto-col>
                                    <auto-col class="x-w-list-item-content" min="410" size="0 1 600px">
                                        <div class="x-w-list-item-label text-line-1">民政部对“关于进一步加快推进居家养老服务体系建设的提案”的答复</div>
                                        <p class="x-w-list-item-text text-line-2">
                                            关于鼓励社会力量参与发展养老服务、加大养老服务人才培训力度等建议针对性强，对于加强居家养老服务建设，构建居家社区机构相协调、医养康养相结合的养老服务体系具有很好的参考意义，我们将认真研究吸纳。
                                        </p>
                                        <p>
                                            <router-link class="router-but" :to="{path:'/aboutUs/detail',query:{id:5}}">
                                                <span>点击进入详情</span>
                                                <span class="router-but-icon"></span>
                                            </router-link>
                                        </p>
                                    </auto-col>
                                </auto-row>
                            </auto-col>
                            <auto-col class="x-w-list-item-next-box" min="600" size="780">
                                <router-link :to="{path:'/aboutUs/detail',query:{id:item1.id}}" class="x-w-list-item" v-for="(item1,index1) of x_w_list" :key="index1">
                                    <auto-row class="x-w-list-item-box">
                                        <auto-col class="x-w-list-item-date" justify="center" items="center">
                                            <div>
                                                <p class="date-dd">{{item1.date.slice(8,10)}}</p>
                                                <p class="date-yyyy-mm">{{item1.date.slice(0,7)}}</p>
                                            </div>
                                        </auto-col>
                                        <auto-col class="x-w-list-item-content" size="auto">
                                            <div class="x-w-list-item-label text-line-s">{{item1.title}}</div>
                                            <p class="x-w-list-item-text text-line-2">
                                                {{item1.content}}
                                            </p>
                                        </auto-col>
                                        <auto-col class="router-but" items="center">
                                            <span class="router-but-icon"></span>
                                        </auto-col>
                                    </auto-row>
                                </router-link>
                            </auto-col>
                        </auto-row>
                    </div>

                </div>
            </div>
        </div>
        <form-box></form-box>
        <footer-box></footer-box>
        <fixed-box></fixed-box>
    </div>
</template>

<script>
    import {
        autoRow,
        autoCol
    } from "@/components"
    import headNav from "@/components/headNav"
    import formBox from "@/components/formBox"
    import footerBox from "@/components/footerBox"
    import fixedBox from "@/components/fixedBox"
    import { swiper, swiperSlide} from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'
    export default {
        name: '',
        components: {
            autoRow,
            autoCol,
            headNav,
            formBox,
            footerBox,
            fixedBox,
            swiper,
            swiperSlide,
        },
        mixins:[],
        props: [],
        data() {
            return {
                swiperOption:{
                    // loop: true,
                    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, // 修改swiper的父元素时，自动初始化swiper
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false // 手动滑动后继续自动播放
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                },
                z_h__l_p_t_y_f_y_y_list:[
                    {label:"居家", icon:require('@/assets/img/01jujia.png')},
                    {label:"社区", icon:require('@/assets/img/02shequ.png')},
                    {label:"机构", icon:require('@/assets/img/03jigou.png')},
                    {label:"旅居", icon:require('@/assets/img/04lvju.png')},
                    {label:"医疗", icon:require('@/assets/img/05yiyang.png')},
                    {label:"集团化管理", icon:require('@/assets/img/06jituanhua.png')},
                    {label:"政府监督", icon:require('@/assets/img/06zhengfu.png')},
                ],
                z_s_f_a_list:[
                    {label:"社区养老解决方案", icons:[require('@/assets/img/icon-01.png'),require('@/assets/img/icon-11.png')], texts:['操作流程规范便捷 / 工单质量监管透明','健康监测实时上传 / 和活服务一应俱全']},
                    {label:"居家养老解决方案", icons:[require('@/assets/img/icon-02.png'),require('@/assets/img/icon-12.png')], texts:['运营数据可视化 / 24小时无间断监测','管理流程规划智能 / 移动端可随时查看']},
                    {label:"机构养老解决方案", icons:[require('@/assets/img/icon-03.png'),require('@/assets/img/icon-13.png')], texts:['业务流程科学规范 / 运营数据可视化','护理服务流程完整 / 24小时无间断监控']},
                    {label:"养老评估解决方案", icons:[require('@/assets/img/icon-04.png'),require('@/assets/img/icon-14.png')], texts:['专项模板植入系统','支持自定义评估模板 / 完备的评估管理中心']},
                    {label:"家庭养老床位解决方案", icons:[require('@/assets/img/icon-05.png'),require('@/assets/img/icon-15.png')], texts:['海量业务验证 / 智慧安防保驾护航','工单质量监管透明 / 运营数据可视化']},
                    {label:"设备物联解决方案", icons:[require('@/assets/img/icon-06.png'),require('@/assets/img/icon-16.png')], texts:['物联网全屋智能监测、智能安防、紧急呼叫、健康监测、智能预警、紧急救援等']},
                ],
                x_w_list:[
                    {
                        id:2,
                        title:'国务院关于印发“十四五”国家老龄事业发展和养老服务体系规划的通知',
                        content:'为实施积极应对人口老龄化国家战略，推动老龄事业和产业协同发展，构建和完善兜底性、普惠型、多样化的养老服务体系，不断满足老年人日益增长的多层次、高品质健康养老需求，根据《中华人民共和国老年人权益保障法》、《中华人民共和国国民经济和社会发展第十四个五年规划和2035年远景目标纲要》和《国家积极应对人口老龄化中长期规划》，制定本规划。',
                        date:'2021-12-30',
                        orange:'国务院',
                        icon:require('@/assets/image/aboutUs/04/pic.png')
                    },
                    {
                        id:1,
                        title:'国务院办公厅关于促进 养老托育服务健康发展的意见',
                        content:'各省、自治区、直辖市人民政府，国务院各部委、各直属机构：促进养老托育服务健康发展，有利于改善民生福祉，有利于促进家庭和谐，有利于培育经济发展新动能。为贯彻落实党中央、国务院决策部署，更好发挥各级政府作用，更充分激发社会力量活力，更好实现社会效益和经济效益相统一，持续提高人民群众的获得感、幸福感、安全感，经国务院同意，现提出以下意见。',
                        date:'2020-12-14',
                        orange:'国务院办公厅',
                        icon:require('@/assets/image/aboutUs/04/pic.png')
                    },
                    {
                        id:4,
                        title:'国务院办公厅关于推进养老服务发展的意见',
                        content:'党中央、国务院高度重视养老服务，党的十八大以来，出台了加快发展养老服务业、全面放开养老服务市场等政策措施，养老服务体系建设取得显著成效。但总的看，养老服务市场活力尚未充分激发，发展不平衡不充分、有效供给不足、服务质量不高等问题依然存在，人民群众养老服务需求尚未有效满足。',
                        date:'2019-03-29',
                        orange:'国务院办公厅',
                        icon:require('@/assets/image/aboutUs/04/pic.png')
                    },
                    {
                        id:3,
                        title:'国务院关于加快发展养老服务业的若干意见',
                        content:'近年来，我国养老服务业快速发展，以居家为基础、社区为依托、机构为支撑的养老服务体系初步建立，老年消费市场初步形成，老龄事业发展取得显著成就。但总体上看，养老服务和产品供给不足、市场发育不健全、城乡区域发展不平衡等问题还十分突出。当前，我国已经进入人口老龄化快速发展阶段，2012年底我国60周岁以上老年人口已达1.94亿，2020年将达到2.43亿，2025年将突破3亿。积极应对人口老龄化，加快发展养老服务业，不断满足老年人持续增长的养老服务需求，是全面建成小康社会的一项紧迫任务，有利于保障老年人权益，共享改革发展成果，有利于拉动消费、扩大就业，有利于保障和改善民生，促进社会和谐，推进经济社会持续健康发展。为加快发展养老服务业',
                        date:'2013-09-06',
                        orange:'国务院',
                        icon:require('@/assets/image/aboutUs/04/pic.png')
                    }
                ]
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器

        },
        methods: {

        },
        created(){

        }, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
        mounted(){

        }, //模板编译/挂载之后(不保证组件已在document中)
        beforeRouteLeave(to, from, next) {

            next();
        },
//        beforeCreate(){}, //组件实例刚被创建，组件属性计算之前，如data属性等
//        beforeMount(){}, //模板编译/挂载之前
//        beforeUpdate(){}, //组件更新之前
//        updated(){}, //组件更新之后
//        activated(){}, //for keep-alive,组件被激活时调用
//        deactivated(){}, //for keep-alive,组件被激活时调用
//        beforeDestory(){}, //组件销毁前调用
//        destoryed(){}, //组件销毁后调用
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="@/assets/css/home.css" scoped></style>
